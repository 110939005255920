<template>
  <v-main>
    <v-container :fill-height="!authenticated" fluid>
      <v-row>
        <v-col cols="12">
          <router-view />
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "CoreView",
  computed: {
    authenticated: {
      get() {
        return this.$store.getters["auth/isAuthenticated"];
      },
    },
  },
};
</script>
